<template>
  <div class="nav-bar">
    <div class="bar-content justify-between">
      <div class="bar-center">
        <div @click="Jump('/loginHome')" v-show="false" class="bar-logo">
          <img :src="logopath" class="bar-logo" />
        </div>
        <div class="d-flex" style="margin-left:20px">
          <!-- <div class="item cursor-p" :class="{ 'active': mdd === 1 }" v-if="(this.users)" @click="Jump('/loginHome', 1)">
            课程中心</div>
          <div class="item cursor-p" :class="{ 'active': mdd === 1 }" v-else @click="Jump('/home', 1)">首页</div> -->
          <div class="item cursor-p" :class="{ active: mdd === 1 }" v-if="this.users" @click="Jump('/loginHome', 1)">
            课程中心
          </div>
          <div class="item cursor-p" :class="{ active: mdd === 1 }" v-else @click="Jump('/loginHome', 1)">
            首页
          </div>
          <div class="item cursor-p" :class="{ active: mdd === 2 }" @click="Jump('/SchoolCollaboration', 2)">
            家校共育
          </div>
          <!-- <div
            class="item cursor-p"
            :class="{ active: mdd === 3 }"
            @click="Jump('/TeachingResearch', 3)"
          >
            教学教研
          </div> -->
          <div class="item cursor-p" :class="{ active: mdd === 3 }" @click="Jump('/TeachingResearch', 3)">
            教学教研
          </div>
          <!-- <div class="item cursor-p" :class="{ 'active': mdd === 3 }"  @click="Wh(3)">
							教学教研</div> -->
          <div class="item cursor-p" :class="{ active: mdd === 4 }" @click="Urls('http://118.195.190.161/', 4)">
            心理测评
          </div>
          <div class="item cursor-p" :class="{ active: mdd === 5 }" @click="Jump('/CasePresentation', 5)">
            案例展示
          </div>
          <div class="item cursor-p" :class="{ active: mdd === 6 }" @click="Jump('/AboutUs', 6)">
            关于我们
          </div>
          <div v-if="this.users &&
          (this.users.userRole == 1 || this.users.userRole == 2 || this.users.userRole == 3)
          " class="item cursor-p" :class="{ active: mdd === 7 }" @click="Jump('/SchoolCourseDuration', 7)">
            学校分析
          </div>
          <div v-if="this.users && (this.users.userRole == 77 || this.users.userRole == 88 || this.users.userRole == 99)
          " class="item cursor-p" :class="{ active: mdd === 8 }" @click="Jump('/TeacherCourseDuration', 8)">
            教师分析
          </div>
          <div v-if="this.users &&
          (this.users.userRole == 1 ||
            this.users.userRole == 2 ||
            this.users.userRole == 3)
          " class="item cursor-p" @click="Urls('http://screen.xyjy.cc/')">
            数据中心
          </div>
        </div>
      </div>
      <div slot="right" class="cursor-p">
        <img src="../../../assets/img/home/icon_people.png" style="margin-bottom: -2px" class="bar-icon" />
        <span class="ml-5" v-if="!this.users" @click="requestLogin">登录</span>
        <span class="ml-5" v-else @click="requestLoginHome">欢迎您，{{ users.name }}</span>
        <el-dropdown class="ml-5" trigger="click" v-if="this.users">
          <div class="avatar-wrapper">
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="dialogInfoVisible = true">
              个人信息
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="dialogFormVisible = true">
              修改密码
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="loginOut">
              <span style="display: block">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else></span>
      </div>
    </div>

    <div class="add-form">
      <el-dialog title="个人信息" :visible.sync="dialogInfoVisible" :close-on-click-modal="false" width="30%">
        <el-form :model="form" ref="infoForm" :rules="rules">
          <!-- <el-form-item label="角色：" :label-width="formLabelWidth">
            {{ fromUser.roleName }}
          </el-form-item> -->
          <el-form-item label="姓名：" :label-width="formLabelWidth">
            {{ fromUser.name }}
          </el-form-item>
          <el-form-item label="手机号：" :label-width="formLabelWidth">
            {{ fromUser.phone }}
          </el-form-item>
          <el-form-item label="登录名：" :label-width="formLabelWidth">
            {{ fromUser.userCode }}
          </el-form-item>
          <el-form-item v-if="this.fromUser.userRole == 2" label="省：" :label-width="formLabelWidth">
            {{ fromUser.province }}
          </el-form-item>
          <el-form-item v-if="this.fromUser.userRole == 2" label="市：" :label-width="formLabelWidth">
            {{ fromUser.city }}
          </el-form-item>
          <el-form-item v-if="this.fromUser.userRole == 2" label="县：" :label-width="formLabelWidth">
            {{ fromUser.county }}
          </el-form-item>
          <el-form-item v-if="this.fromUser.userRole == 3 || this.fromUser.userRole == 4" label="学校："
            :label-width="formLabelWidth">
            {{ fromUser.schoolName }}
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogInfoVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="add-form">
      <el-dialog title="修改密码" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
        <el-form :model="form" ref="pwdForm" :rules="rules">
          <el-form-item label="原密码" prop="password" :label-width="formLabelWidth">
            <el-input v-model="form.password" autocomplete="npassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword" :label-width="formLabelWidth">
            <el-input v-model="form.newPassword" autocomplete="newnewPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPassword" :label-width="formLabelWidth">
            <el-input v-model="form.checkPassword" autocomplete="checkPassword" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit('pwdForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="add-form">
      <el-dialog title="提示" :visible.sync="dialogWhVisible" :close-on-click-modal="false">
        <el-form :model="form" ref="whForm" :rules="rules">
          <img src="../../../assets/img/common/whz.jpeg" style="width: 100%" />
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogWhVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Message, MessageBox } from "element-ui"; //导入element-ui组件库
import { getInfo, getUserInfo, updatePwd, checkPwd } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/cookies.js";
export default {
  name: "NavBar",
  data() {
    // 校验原密码
    let validatePass = (rule, value, callback) => {
      checkPwd({
        password: this.form.password,
      }).then((res) => {
        if (res.data) {
          callback();
        } else {
          callback(new Error(res.message));
        }
      });
    };
    // 检测新密码和确认密码一致性
    let checkpass = (rule, value, callback) => {
      if (value == this.form.newPassword) {
        callback();
      } else {
        callback(new Error("密码不一致"));
      }
    };
    return {
      rules: {
        password: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
          {
            validator: validatePass,
            message: "原密码不正确",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        checkPassword: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur",
          },
          {
            validator: checkpass,
            trigger: "blur",
          },
        ],
      },
      dialogFormVisible: false,
      dialogInfoVisible: false,
      dialogWhVisible: false,
      form: {
        password: "",
        newPassword: "",
        checkPassword: "",
      },
      formLabelWidth: "120px",
      //token用户信息
      users: undefined,
      //用户详细信息
      fromUser: {
        id: "",
        loginId: "",
        name: "",
        phone: "",
        userRole: "",
        schoolId: "",
        schoolName: "",
        roleName: "",
        province: "",
        city: "",
        county: "",
      },
      logopath: '',
      mdd: 0,
      isShowMaxScreen: false,
    };
  },
  created() {
    Promise.all([this.userInfo()]).then(() => {
      this.GetUser();
    });
  },
  methods: {
    //链接跳转
    Urls(value, index) {
      if (index) {
        this.mdd = index;
      }
      window.open(value, "_blank");
    },
    //跳转
    Jump(url, index) {
      if (index) {
        this.mdd = index;
      }
      this.$router.push(url);
    },
    //维护中
    Wh(index) {
      this.dialogWhVisible = true;
      this.mdd = index;
    },
    //退出登录
    loginOut() {
      let hostname = window.location.hostname;
      hostname = hostname.replace("www", "");
      setToken("", {
        expires: 1,
        path: "",
        domain: hostname,
      });
      removeToken();
      this.$router.push("/login");
    },
    //用户信息
    async userInfo() {
      var token = getToken();
      if (token) {
        await getInfo(token).then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.users = response.data;
          }
          setTimeout(() => {
            this.listLoading = false;
          }, 1.5 * 1000);
        });
      }
    },
    //用户详细信息
    GetUser() {
      if (this.users) {
        getUserInfo({
          id: this.users.id,
        }).then((response) => {
          if (response.status == 200) {
            this.fromUser = response.data;
            if (this.fromUser.userCode == 'mcxjyj') {
              this.logopath = 'https://xyjyimg.oss-cn-zhangjiakou.aliyuncs.com/33.png'
            } else {
              this.logopath = 'https://xyjyimg.oss-cn-zhangjiakou.aliyuncs.com/logo.png'
            }
          }
          setTimeout(() => {
            this.listLoading = false;
          }, 1.5 * 1000);
        });
      } else {
        this.logopath = 'https://xyjyimg.oss-cn-zhangjiakou.aliyuncs.com/logo.png'
      }
    },
    //登录
    requestLogin() {
      this.$router.push("/login");
    },
    //跳转登录后首页
    requestLoginHome() {
      this.$router.push("/loginHome");
    },
    //保存密码
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePwd(this.form).then((response) => {
            if (response.status == 200) {
              this.dialogFormVisible = false;
              MessageBox.alert("密码修改成功", "提示", {
                confirmButtonText: "确定",
                type: "info",
              });
            }
            setTimeout(() => {
              this.listLoading = false;
            }, 1.5 * 1000);
          });
        } else {
          return false;
        }
      });
    },
  },
  handleCommand() {
    if (command == "a") {
      this.dialogFormVisible = true;
      this.dialogInfoVisible = true;
      dialogWhVisible = true;
    }
  },
};
</script>

<style scoped lang="less">
.nav-bar {
  background: url("../../../assets/img/home/top_bg.png") no-repeat;
  background-size: 100% 100%;
  height: 5.7rem;

  .bar-content {
    padding-top: 1rem;
    padding-left: 8.1rem;
    padding-right: 8.1rem;
    height: 39px;
    line-height: 39px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    font-size: 18px;

    .bar-center {
      display: flex;
      flex-wrap: wrap;
    }

    .bar-logo {
      margin-right: 2.9rem;
      width: 7.5rem;
      vertical-align: middle;
      cursor: pointer;
    }

    .item {
      margin-right: 1.3rem;
      //font-size: initial;
      font-size: 18px;
      transition: all 0.1s;

      &:last-child {
        margin-right: 0px;
      }
    }

    .item:hover {
      transform: scale(1.1);
      color: orange;
      border-bottom: 2px solid #7a95f5;
    }

    .span {
      margin-left: 10px;
    }

    .spanout {
      margin-left: 25px;
    }

    .ml-5 {
      font-size: 18px;
    }

    .active {
      color: orange;
      border-radius: 8px;
    }
  }
}
</style>