<template>
	<div class="bottom-bar justify-between">
		<!-- 		<div class="bar-copy cursor-p">主办：中科院合肥创新院心理健康研究中心 技术支持：安徽中科中云教育科技有限公司 版本号 V.2.0</div>
		<div class="bar-copy cursor-p" @click="Urls('https://beian.miit.gov.cn/#/Integrated/index')">皖ICP备2023008085号-1
		</div> -->
		<!-- <div class="bar-about cursor-p" @click="Jump('/AboutUs')">关于我们</div> -->
		<div class="bottom">
			<span class="style4"> </span>
			<span an class="style4"> </span>
			<span class="style4">主办：中科院合肥创新院心理健康研究中心 &nbsp;&nbsp;&nbsp;&nbsp;技术支持：安徽中科中云教育科技有限公司 版本号 V.2.0</span>
			<span>
				<a href="https://beian.mps.gov.cn/#/query/webSearch?code=2023008085" target="_blank" style="color:#fff"
					rel="noopener">
					<img src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"
						style="width:16px;height:16px;vertical-align:middle;">
					<sapn style="height:16px;vertical-align:middle;">皖ICP备2023008085号-1</sapn>
				</a>
			</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'BottomBar',
		data() {
			return {

			}
		},
		methods: {
			Urls(value) {
				window.open(value, '_blank');
			},
			Jump(url) {
				this.$router.push(url);
			},
		}
	}
</script>
<style scoped>
	.bottom {
		position: relative;
		right: 0px;
		bottom: 0px;
		color: #fff;
		display: block;
		width: 100%;
		text-align: center;
		z-index: 10;
	}

	.bottom span {
		position: relative;
		display: block;
		left: 0;
	}

	.bottom-bar {
		padding-left: 9.1rem;
		padding-right: 9.1rem;
		background-color: #373737;
		line-height: 2rem;
		font-size: 12px;
		height: 5rem;
	}

	.bottom-bar .bar-copy {
		color: #F9F9F9;
		opacity: 0.8;
		display: flex;
	}

	.bottom-bar .bar-about {
		color: #FFFFFF;
	}
</style>