import request from "@/utils/request";  //导入封装请求的js文件
//登录
export function login(data) {
	return request({
		url: '/Common/Login',
		method: 'post',
		data
	})
}

//获取用户信息
export function getInfo() {
	return request({
	  url: '/UserManage/GetUser',
	  method: 'get'	 
	})
  }
  //获取用户信息
export function getUserInfo(query) {
	return request({
	  url: '/UserManage/Get',
	  method: 'get',
	  params: query
	})
  }

//修改密码
export function updatePwd(data) {
	return request({
	  url: '/UserManage/ModifyPwd',
	  method: 'post',
		data
	})
}

//验证密码
export function checkPwd(data) {
	return request({
	  url: '/UserManage/CheckPwd',
	  method: 'post',
		data
	})
}