import request from "@/utils/request";  //导入封装请求的js文件

//获取详细信息
export function NewsInfo(query) {
	return request({
		url: '/News/Get',
		method: 'get',
		params: query
	})
}

//获取列表信息
export function PageList(data) {
	return request({
		url: '/News/PageList',
		method: 'post',
		data
	})
}

//分页查询各地教研
export function PageTeachNewList(data) {
	return request({
		url: '/News/PageTeachNewList',
		method: 'post',
		data
	})
}

//获取单个各地教研
export function GetTeachNew(query) {
	return request({
		url: '/News/GetTeachNew',
		method: 'get',
		params: query
	})
}

//分页查询名师名课视频
export function PageTeacherVideoList(data) {
	return request({
		url: '/News/PageTeacherVideoList',
		method: 'post',
		data
	})
}

//获取单个名师名课视频
export function GetVideo(query) {
	return request({
		url: '/News/GetVideo',
		method: 'get',
		params: query
	})
}

//获取案例列表信息
export function CaseList(data) {
	return request({
		url: '/News/CaseList',
		method: 'post',
		data
	})
}

//获取关于我们
export function NewsAbout(query) {
	return request({
		url: '/News/GetAbout',
		method: 'get',
		params: query
	})
}